<template>
  <div>
    <CHeader position="sticky" class="mb-4">
      <CContainer fluid>
        <CNavbarBrand
          @click="
            () => {
              lgDemo = true
            }
          "
        >
          <div class="avatar rounded-0">
            <img class="avatar-img rounded-0" :src="Logo" alt="Vagrant Bloom" />
          </div>
          Vagrant Bloom (Poorman's Bloomberg)
        </CNavbarBrand>

        <CHeaderNav>
          <CNavItem>
            <div
              class="btn-group"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                type="button"
                class="btn ms-1 mx-auto"
                v-on:click="isStock = false"
                v-bind:class="{
                  'btn-outline-primary': isStock,
                  'btn-primary': !isStock,
                }"
              >
                Market
              </button>

              <button
                type="button"
                class="btn ms-1 mx-auto"
                v-on:click="isStock = true"
                v-bind:class="{
                  'btn-outline-success': !isStock,
                  'btn-success': isStock,
                }"
              >
                Stock
              </button>
            </div>
          </CNavItem>
          <CNavItem>
            <CCollapse class="header-collapse" :visible="isStock">
              <input
                class="form-control ms-1 mx-auto"
                placeholder="MSFT"
                v-model.lazy="ticker"
                id="tickerInput"
                v-on:keypress="isLetter($event)"
              />
            </CCollapse>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink
              href="https://www.buymeacoffee.com/vagrantb"
              target="_blank"
            >
              <CIcon class="mx-2" icon="cilStar" size="lg" /> Support This
              Project
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
      </CContainer>

      <CModal
        size="lg"
        alignment="center"
        :visible="lgDemo"
        @close="
          () => {
            lgDemo = false
          }
        "
      >
        <CModalHeader>
          <CModalTitle>Vagrant Bloom is a personal passion project</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <b>Feedback and Feature requests:</b>
          <a href="https://www.reddit.com/r/vagrantbloom/" target="_blank"
            >r/vagrantbloom</a
          >
          <br />
          These are not Financial Advices, I am not responsible for your loss,
          <br />...but feel free to give me credit for your wins. <br />
          <CCard style="width: 18rem">
                      <img
            class="d-block w-100"
            src="../assets/images/BTCwallet.jpg"
            alt="slide 1"
          />
            <CCardBody>
              <CCardTitle>BTC Donation</CCardTitle>
              <CCardText
                >
                17TTCBZxoYj6z3W24jFfr7W9pJqS3Hked
                </CCardText
              >
            </CCardBody>
          </CCard>

          Discord: Ace#3288 <br />
          ©VagrantBloom 2022.
        </CModalBody>
      </CModal>
    </CHeader>
    <div class="wrapper d-flex flex-column">
      <div class="body flex-grow-1 px-3">
        <CContainer xxl>
          <SearchCenter />

          <Dashboard :ticker="ticker" :key="ticker" v-if="isStock" />
          <Marketboard v-if="!isStock" />
        </CContainer>
      </div>
    </div>
    <CFooter>
      <div>
        <span
          >Vagrant Bloom (Poorman's Bloomberg) is a personal passion project.
          These are not Financial Advices, I am not responsible for your loss,
          but feel free to give me credit for your wins.
          <b>Feedback and Feature requests:</b>
          <a href="https://www.reddit.com/r/vagrantbloom/" target="_blank"
            >r/vagrantbloom</a
          >
        </span>
      </div>
      <div>
        <span>&copy; VagrantBloom 2022. Discord Ace#3288</span>
      </div>
    </CFooter>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import Dashboard from '../views/Dashboard.vue'
import Marketboard from '../views/Marketboard.vue'
import SearchCenter from '../views/sections/SearchCenterSection.vue'
import Logo from '@/assets/images/logo500_.svg'

export default {
  name: 'DefaultLayout',
  components: {
    CContainer,
    Dashboard,
    Marketboard,
    SearchCenter,
  },
  methods: {
    isLetter(e) {
      if (e.keyCode === 13) return true
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true
      // Match with regex
      else e.preventDefault() // If not match, don't add to input text
    },
  },
  data() {
    return {
      ticker: 'MSFT',
      isStock: false,
      lgDemo: false,
      Logo,
    }
  },
}
</script>
