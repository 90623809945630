<template>
  <CRow>
  <CCol :md="12">
  <CCard class="mb-4">
  <CCardHeader> finviz Technical Analysis </CCardHeader>
    <CImage fluid v-bind:src='"https://charts2.finviz.com/chart.ashx?t=" + this.symbol + "&ta=1"' />
  </CCard>
  </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'Finviz',
  props:{
    symbol: String
  },

}
</script>
