<template>
<CRow>
    <CCol :sm="12" :lg="2">
    </CCol>
    <CCol :sm="12" :lg="3">
        <CChartDoughnut :data="transactions" />
    </CCol>
    <CCol :sm="12" :lg="2">
    </CCol>
    <CCol :sm="12" :lg="3">
        <CChartDoughnut :data="amounts" />
    </CCol>
    <CCol :sm="12" :lg="2">
    </CCol>
</CRow>
  
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'
export default {
  name: 'OIPie',
  props: ['oitems'],
  components: { CChartDoughnut },
  data() {

  },
  computed: {
    defaultData() {
      return {
        labels: ['VueJs', 'EmberJs', 'VueJs', 'loo'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10],
          },
        ],
      }
    },
    transactions() {
        let sells = 0;
        let buys = 0;
        this.oitems.forEach(x => {
            if (parseInt(x.quantity) < 0) {
                sells += 1;
            }
            else {
                buys += 1;
            }
        });
        return {
            labels: ['Buy Transactions', 'Sell Transactions'],
            datasets: [
          {
            backgroundColor: ['#55ed99', '#ed5a55'],
            data: [buys, sells],
          },
        ],
        }
    },
    amounts() {
        let sells = 0;
        let buys = 0;
        this.oitems.forEach(x => {
            if (parseInt(x.quantity) < 0) {
                sells += parseInt(x.quantity);
            }
            else {
                buys += parseInt(x.quantity);
            }
        });
        return {
            labels: ['Shares Bought', 'Shares Sold'],
            datasets: [
          {
            backgroundColor: ['#55ed99', '#ed5a55'],
            data: [buys, Math.abs(sells)],
          },
        ],
        }
    }
  },
}
</script>
