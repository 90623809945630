<template>
    <CRow v-if='!data_loaded'>
  <CCol class="text-center">
  <CSpinner color="success" variant="grow" />
  </CCol>
  </CRow>
  <CRow>
  <CCol :md="12">
    <CCard>
      <CCardHeader>
        StockTwits Sentiment
        <CButton
          color="success"
          variant="outline"
          class="float-end"
          size="sm"
          @click="visible = !visible"
          ><CIcon icon="cilMenu"
        /></CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol :sm="6" :lg="3">
            <CWidgetStatsD
              class="mb-4"
              style="--cui-card-cap-bg: #30db7a"
              :values="[
                {
                  title: 'Bulls',
                  value: `${Math.round(
                    (sitems.bullishCount / sitems.totalCount) * 100,
                  )}%`,
                },
              ]"
            >
              <template #icon
                ><CIcon icon="cilArrowTop" height="52" class="my-4 text-white"
              /></template>
              <template #chart>
                <CChart
                  class="position-absolute w-100 h-100"
                  type="line"
                  :data="{
                    labels: [
                      'Bulls are fuk',
                      'Bulls are fuk',
                      'Bulls are fuk',
                      'Bulls are fuk',
                      'Bulls are fuk',
                      'Bulls are fuk',
                      'Bulls are fuk',
                    ],
                    datasets: [
                      {
                        backgroundColor: 'rgba(255,255,255,.1)',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [65, 59, 84, 90, 51, 55, 40].sort(),
                        fill: true,
                      },
                    ],
                  }"
                  :options="options"
                />
              </template>
            </CWidgetStatsD>
          </CCol>
          <CCol :sm="6" :lg="3">
            <CWidgetStatsD
              class="mb-4"
              style="--cui-card-cap-bg: #00aced"
              :values="[
                {
                  title: 'Unsure',
                  value: `${Math.round(
                    ((sitems.totalCount -
                      sitems.bullishCount -
                      sitems.bearishCount) /
                      sitems.totalCount) *
                      100,
                  )}%`,
                },
              ]"
            >
              <template #icon
                ><CIcon
                  icon="cilArrowRight"
                  height="52"
                  class="my-4 text-white"
              /></template>
              <template #chart>
                <CChart
                  class="position-absolute w-100 h-100"
                  type="line"
                  :data="{
                    labels: [
                      'You are fuk',
                      'You are fuk',
                      'You are fuk',
                      'You are fuk',
                      'You are fuk',
                      'You are fuk',
                      'You are fuk',
                    ],
                    datasets: [
                      {
                        backgroundColor: 'rgba(255,255,255,.1)',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [10, 11, 8, 10, 12, 9, 10],
                        fill: true,
                      },
                    ],
                  }"
                  :options="options"
                />
              </template>
            </CWidgetStatsD>
          </CCol>
          <CCol :sm="6" :lg="3">
            <CWidgetStatsD
              class="mb-4"
              style="--cui-card-cap-bg: #e04b22"
              :values="[
                {
                  title: 'Bears',
                  value: `${Math.round(
                    (sitems.bearishCount / sitems.totalCount) * 100,
                  )}%`,
                },
              ]"
            >
              <template #icon
                ><CIcon
                  icon="cilArrowBottom"
                  height="52"
                  class="my-4 text-white"
              /></template>
              <template #chart>
                <CChart
                  class="position-absolute w-100 h-100"
                  type="line"
                  :data="{
                    labels: [
                      'Bears are fuk',
                      'Bears are fuk',
                      'Bears are fuk',
                      'Bears are fuk',
                      'Bears are fuk',
                      'Bears are fuk',
                      'Bears are fuk',
                    ],
                    datasets: [
                      {
                        backgroundColor: 'rgba(255,255,255,.1)',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [78, 81, 80, 45, 34, 12, 40].sort().reverse(),
                        fill: true,
                      },
                    ],
                  }"
                  :options="options"
                />
              </template>
            </CWidgetStatsD>
          </CCol>
          <CCol :sm="6" :lg="3">
            <CWidgetStatsD
              class="mb-4"
              color="warning"
              :values="[
                {
                  title: 'Stocktwits comments',
                  value: `Based on ${sitems.totalCount}`,
                },
              ]"
            >
              <template #icon
                ><CIcon icon="cilPeople" height="52" class="my-4 text-white"
              /></template>
              <template #chart>
                <CChart
                  class="position-absolute w-100 h-100"
                  type="doughnut"
                  :data="{
                    labels: ['Bulls', 'Unsure', 'Bears'],
                    datasets: [
                      {
                        backgroundColor: 'rgba(255,255,255,.1)',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [23, 63, 23],
                        fill: true,
                      },
                    ],
                  }"
                  :options="options"
                />
              </template>
            </CWidgetStatsD>
          </CCol>
        </CRow>
        <CCollapse :visible="visible">
          <CRow>
            <CCol :md="12">
              <CTable>
                <CTableBody>
                  <CTableRow v-for="m in messages" :key="m.id">
                    <CTableHeaderCell scope="row"
                      ><CAvatar :src="m.user.avatar_url" shape="rounded"
                    /></CTableHeaderCell>
                    <CTableDataCell>{{ m.user.username }}</CTableDataCell>
                    <CTableDataCell>{{ m.body }}</CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </CCol>
          </CRow>
        </CCollapse>
      </CCardBody>
    </CCard>
  </CCol>
  </CRow>
</template>

<script>
import { CChart } from '@coreui/vue-chartjs'
import axios from 'axios'
export default {
  name: 'Stocktwits',
  components: {
    CChart,
  },
  props: {
    symbol: String,
  },
  data() {
    return {
      data_loaded: false,
      sitems: [],
      messages: [],
      visible: false,
    }
  },
  methods: {
    async getData() {
      axios
        .get('https://vbapi-hepeem5jva-uc.a.run.app/sentiment', {
          headers: {
            ticker: this.symbol,
          },
        })
        .then((response) => {
          this.sitems = response.data
          this.messages = response.data.messages
          this.data_loaded = true
        })
    },
  },
  created() {
    this.getData()
  },
  setup() {
    const options = {
      elements: {
        line: {
          tension: 0.4,
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    }

    return {
      options,
    }
  },
}
</script>
