<template>
  <CRow v-if="!data_loaded">
    <CCol class="text-center">
      <CSpinner color="success" variant="grow" />
    </CCol>
  </CRow>
  <CRow>
    <CCol :md="12">
      <CCard>
        <CCardHeader>
          Earnings (Source: SeekingAlpha)
          <CTooltip
            content="Not in its final form but usable. PRE = Pre-market, POST = Post-market, DUR = During-market, EST = Estimated "
            placement="right"
          >
            <template #toggler="{ on }">
              <CButton color="info" v-on="on">New</CButton>
            </template>
          </CTooltip>
          <CButton
            color="success"
            variant="outline"
            class="float-end"
            size="sm"
            @click="visible = !visible"
            ><CIcon icon="cilMenu"
          /></CButton>
        </CCardHeader>
        <CCardBody>
          <CCollapse :visible="visible">
            <CRow>
              <CCol v-for="item in oitems" :sm="6" :lg="2" :key="item.Ticker">
                <CWidgetStatsF
                  v-if="item.Time == 'Pre-Market'"
                  color="warning"
                  :title="`${new Date(item.Date)
                    .toISOString()
                    .substring(5, 10)} Pre`"
                  :value="item.Ticker"
                >
                  <template #icon>
                    <CIcon icon="cilSun" size="xl" />
                  </template>
                  <template #footer>
                    <CLink
                      class="font-weight-bold font-xs text-medium-emphasis"
                      :href="`https://www.tradingview.com/chart/?share_your_love=ace95archer&symbol=${item.Ticker}`"
                      rel="noopener norefferer"
                      target="_blank"
                    >
                      {{ item.Ticker }}
                      <CIcon
                        icon="cil-arrow-right"
                        class="ms-auto"
                        width="16"
                      />
                    </CLink>
                  </template>
                </CWidgetStatsF>
                <CWidgetStatsF
                  v-if="item.Time == 'Post-Market'"
                  color="primary"
                  :title="`${new Date(item.Date)
                    .toISOString()
                    .substring(5, 10)} Post`"
                  :value="item.Ticker"
                >
                  <template #icon>
                    <CIcon icon="cilMoon" size="xl" />
                  </template>
                  <template #footer>
                    <CLink
                      class="font-weight-bold font-xs text-medium-emphasis"
                      :href="`https://www.tradingview.com/chart/?share_your_love=ace95archer&symbol=${item.Ticker}`"
                      rel="noopener norefferer"
                      target="_blank"
                    >
                      {{ item.Ticker }}
                      <CIcon
                        icon="cil-arrow-right"
                        class="ms-auto"
                        width="16"
                      />
                    </CLink>
                  </template>
                </CWidgetStatsF>
                <CWidgetStatsF
                  v-if="item.Time == 'Estimated'"
                  color="secondary"
                  :title="`${new Date(item.Date)
                    .toISOString()
                    .substring(5, 10)} Est`"
                  :value="item.Ticker"
                >
                  <template #icon>
                    <CIcon icon="cilCalendar" size="xl" />
                  </template>
                  <template #footer>
                    <CLink
                      class="font-weight-bold font-xs text-medium-emphasis"
                      :href="`https://www.tradingview.com/chart/?share_your_love=ace95archer&symbol=${item.Ticker}`"
                      rel="noopener norefferer"
                      target="_blank"
                    >
                      {{ item.Ticker }}
                      <CIcon
                        icon="cil-arrow-right"
                        class="ms-auto"
                        width="16"
                      />
                    </CLink>
                  </template>
                </CWidgetStatsF>
                <CWidgetStatsF
                  v-if="item.Time == 'During-Market'"
                  color="info"
                  :title="`${new Date(item.Date)
                    .toISOString()
                    .substring(5, 10)} Dur`"
                  :value="item.Ticker"
                >
                  <template #icon>
                    <CIcon icon="cilBell" size="xl" />
                  </template>
                  <template #footer>
                    <CLink
                      class="font-weight-bold font-xs text-medium-emphasis"
                      :href="`https://www.tradingview.com/chart/?share_your_love=ace95archer&symbol=${item.Ticker}`"
                      rel="noopener norefferer"
                      target="_blank"
                    >
                      {{ item.Ticker }}
                      <CIcon
                        icon="cil-arrow-right"
                        class="ms-auto"
                        width="16"
                      />
                    </CLink>
                  </template>
                </CWidgetStatsF>
              </CCol>
            </CRow>
          </CCollapse>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Earnings',

  props: {
    symbol: String,
  },
  data() {
    return {
      data_loaded: false,
      oitems: [],
      messages: [],
      visible: false,
    }
  },
  methods: {
    async getData() {
      axios
        .get('https://vbapi-hepeem5jva-uc.a.run.app/earnings', {
          headers: {
            pages: 2,
          },
        })
        .then((response) => {
          this.oitems = response.data.data
          this.data_loaded = true
        })
    },
  },
  created() {
    this.getData()
  },
  setup() {
    const options = {
      elements: {
        line: {
          tension: 0.4,
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    }

    return {
      options,
    }
  },
}
</script>
