<template>
  <CRow v-if='!data_loaded'>
  <CCol class="text-center">
  <CSpinner color="success" variant="grow" />
  </CCol>
  </CRow>
  <CRow>
    <CCol :md="12">
      <CCard class="mb-4">
        <CCardHeader> IPO Lockup Expiration Tracker (Source: briefing.com) <CButton color="success" variant="outline" class="float-end" size="sm" @click="visible = !visible"><CIcon icon="cilMenu" /></CButton>  </CCardHeader>
        <CCollapse :visible="visible">
        <CCardBody v-if="data_loaded">
         <OIChart/>
         <OIPie :oitems='oitems'
         :key='oitems'
         />
          <CTable align="middle" class="mb-0 border" hover responsive>
            <CTableHead color="light">
              <CTableRow>
                <CTableHeaderCell class="text-center">Ticker</CTableHeaderCell>
                <CTableHeaderCell class="text-center">Issued Price</CTableHeaderCell>
                <CTableHeaderCell class="text-center">Opened Price</CTableHeaderCell>
                <CTableHeaderCell class="text-center">IPO Date</CTableHeaderCell>
                <CTableHeaderCell class="text-center">Expiration Date</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow v-for="item in oitems" :key="item.filling_date">
                <CTableDataCell class="text-center">
                  <div>{{ item.ticker }}</div>
                </CTableDataCell>
                <CTableDataCell class="text-center">
                  <div>${{ item.issue_price }}</div>
                </CTableDataCell>
                <CTableDataCell class="text-center">
                  <div>{{ item.opened_price }}</div>
                </CTableDataCell>
                 <CTableDataCell class="text-center">
                  <div>{{ item.initial_date }}</div>
                </CTableDataCell>
                <CTableDataCell class="text-center">
                  <div >
                  {{ item.expire }}
                   
                  </div>

                </CTableDataCell>
              </CTableRow>
              <CTableRow> </CTableRow>
            </CTableBody>
          </CTable>
        </CCardBody>
        
        <CCardBody v-else>
            <div>Data Loading...</div>
        </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Lockup',
    data() {
        return {
            data_loaded: false,
            oitems: [],
            visible: false,
        }
    },
    methods:{
        async getData() {
            axios
            .get('https://vbapi-hepeem5jva-uc.a.run.app/lockup', {
                headers: {
                    'ticker':this.symbol,
                }
            }).then(response => {
                this.oitems = response.data;
                this.data_loaded = true;
            })
        }
    }, 
    created() {
        this.getData()
    }
}
</script>
