<template>
  <CRow>
    <CCol :md="12">
      <CCard class="mb-4">
        <CCardHeader> {{ symbol }} Insider Transactions (Source: OpenInsider.com)<CButton color="success" variant="outline" class="float-end" size="sm" @click="visible = !visible"><CIcon icon="cilMenu" /></CButton> </CCardHeader>
        <CCardBody v-if="data_loaded">
         <OIPie :oitems='oitems'
         :key='oitems'
         />
         <CCollapse :visible="visible">
          <CTable align="middle" class="mb-0 border" hover responsive>
            <CTableHead color="light">
              <CTableRow>
                <CTableHeaderCell>Name</CTableHeaderCell>
                <CTableHeaderCell class="text-center"> Price </CTableHeaderCell>
                <CTableHeaderCell class="text-center">Shares</CTableHeaderCell>
                <CTableHeaderCell class="text-center">Value</CTableHeaderCell>
                <CTableHeaderCell>Quantity</CTableHeaderCell>
                <CTableHeaderCell class="text-center">Side</CTableHeaderCell>
                <CTableHeaderCell>Date</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow v-for="item in oitems" :key="item.filling_date">
                <CTableDataCell>
                  <div>{{ item.name }}</div>
                  <div class="small text-medium-emphasis">
                    {{ item.title }}
                  </div>
                </CTableDataCell>
                <CTableDataCell class="text-center">
                  <div>${{ item.price }}</div>
                </CTableDataCell>
                <CTableDataCell class="text-center">
                  <div>{{ item.quantity }}</div>
                </CTableDataCell>
                 <CTableDataCell class="text-center">
                  <div>{{ item.value }}</div>
                </CTableDataCell>
                <CTableDataCell>
                  <div class="clearfix">
                  <div class="float-start" v-if="item.owned <= 10" >
                      <strong>Unknown %</strong>
                    </div>
                    <div class="float-start" v-else>
                      <strong>{{ item.delta }}%</strong>
                    </div>
                    <div class="float-end">
                    <small class="text-medium-emphasis" v-if="item.owned <= 10">
                        Had Unknown Amount
                      </small>
                      <small class="text-medium-emphasis" v-else>
                        Now has {{ item.owned }} shrs
                      </small>
                    </div>
                  </div>
                  <CProgress thin :color="'danger'" :value="100 + parseInt(item.delta)" v-if="parseInt(item.quantity) < 0"/>
                  <CProgress thin :color="'info'" :value="100" v-else-if="parseInt(item.owned) == 0"/>
                  <CProgress thin :color="'success'" :value="100 + parseInt(item.delta)" v-else/>

                </CTableDataCell>
                <CTableDataCell class="text-center">
                  {{ item.trade_type }}
                </CTableDataCell>
                <CTableDataCell>
                  <div class="small text-medium-emphasis">Filed at</div>
                  <strong>{{ item.filling_date }}</strong>
                </CTableDataCell>
              </CTableRow>
              <CTableRow> </CTableRow>
            </CTableBody>
          </CTable>
          </CCollapse>
        </CCardBody>
        <CCardBody v-else>
            <div>Data Loading...</div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
// import OIChart from '../charts/OIChart.vue'
import OIPie from '../charts/OIPie.vue'
export default {
  props:{
    symbol: String
  },
  components: {
    // OIChart, 
    OIPie
  },
    data() {
        return {
            data_loaded: false,
            oitems: [],
            visible: false,
        }
    },
    methods:{
        async getData() {
            axios
            .get('https://vbapi-hepeem5jva-uc.a.run.app/oi', {
                headers: {
                    'ticker':this.symbol,
                }
            }).then(response => {
                this.oitems = response.data;
                this.data_loaded = true;
            })
        }
    }, 
    created() {
        this.getData()
    }
}
</script>
