<template>

<CRow>

<CCol :md="12">
    <CCard class="mb-4">

    
    <CCardBody>
        <CInputGroup class="mb-3">
            <CFormInput placeholder="Symbol" aria-label="Anywhere" id="floatingInput" v-model='query' v-on:keyup.enter="searchTv1"/>
        </CInputGroup>
        <CInputGroup class="mb-3 justify-content-md-center">
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchTv1'>TradingView</CButton>
            <CButton type="button" color="danger" variant="outline" id="button-addon2" @click='searchSec1'>SEC</CButton>
            <CButton type="button" color="primary" variant="outline" id="button-addon2" @click='searchHouse1'>House Watcher</CButton>
            <CButton type="button" color="danger" variant="outline" id="button-addon2" @click='searchWsb1'>WSB</CButton>
            <CButton type="button" color="dark" variant="outline" id="button-addon2" @click='searchWsj1'>WSJ</CButton>
            <CButton type="button" color="info" variant="outline" id="button-addon2" @click='searchCnbc1'>CNBC</CButton>
            <CButton type="button" color="primary" variant="outline" id="button-addon2" @click='searchFv1'>finviz</CButton>
            <CButton type="button" color="warning" variant="outline" id="button-addon2" @click='searchSa1'>SeekingAlpha</CButton>
            <CButton type="button" color="primary" variant="outline" id="button-addon2" @click='searchBloom1'>Bloomberg</CButton>
            <CButton type="button" color="info" variant="outline" id="button-addon2" @click='searchUw1'>Unusual Whale</CButton>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchOi1'>OpenInsider</CButton>
            <CButton type="button" color="info" variant="outline" id="button-addon2" @click='seachTwitter1'>Twitter</CButton>
            <CButton type="button" color="primary" variant="outline" id="button-addon2" @click='searchYf1'>yahoo!</CButton>
            <CButton type="button" color="info" variant="outline" id="button-addon2" @click='searchWhale1'>Whale Wisdom</CButton>
            <CButton type="button" color="danger" variant="outline" id="button-addon2" @click='searchYoutube1'>Youtube</CButton>
            <CButton type="button" color="info" variant="outline" id="button-addon2" @click='searchSt1'>Stocktwits</CButton>
            <CButton type="button" color="danger" variant="outline" id="button-addon2" @click='searchIbd1'>iBorrowDesk</CButton>
            <CButton type="button" color="dark" variant="outline" id="button-addon2" @click='searchMp1'>Max Pain</CButton>
        </CInputGroup>
<CContainer>
  <CRow>
    <CCol sm="auto">
      <a href='https://www.reddit.com/r/wallstreetbets/?f=flair_name%3A%22DD%22' target="_blank"> wsb DD </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.tradingview.com/screener/?share_your_love=ace95archer" target="_blank"> Screener </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.federalreserve.gov/newsevents/calendar.htm" target="_blank"> Fed Calendar </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.nasdaq.com/market-activity/earnings"> Earnings Calendar </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://finra-markets.morningstar.com/BondCenter/Default.jsp"> Finra Bonds </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.gurufocus.com/stock-market-valuations.php"> Buffett Indicator </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://tradingeconomics.com/united-states/inflation-cpi"> Inflation </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.bea.gov/news/2022/gross-domestic-product-fourth-quarter-and-year-2021-advance-estimate#:~:text=Gross%20domestic%20product%20(GDP)%2C,services%20used%20up%20in%20production."> GDP </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.bls.gov/cpi/"> CPI </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.treasury.gov/resource-center/data-chart-center/interest-rates/pages/textview.aspx?data=yield"> Yield Curve </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.bloomberg.com/markets/rates-bonds/government-bonds/us"> Rates and Bonds </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.tradingview.com/gopro/?share_your_love=ace95archer"> TradingView </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://finviz.com/" target="_blank"> finviz </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.macrotrends.net/" target="_blank"> Macrotrends </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.wsj.com/" target="_blank"> WSJ </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.wsj.com/market-data" target="_blank"> WSJ Market </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.cnbc.com/" target="_blank"> CNBC </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.bloomberg.com/" target="_blank"> Bloomberg </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.reuters.com/markets/" target="_blank"> Reuters </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.bloomberg.com/businessweek" target="_blank"> Businessweek </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.washingtonpost.com/" target="_blank"> Wash Post </a>
    </CCol>
    <CCol sm="auto">
      <a href='https://www.reddit.com/r/wallstreetbets/?f=flair_name%3A%22News%22' target="_blank"> wsb News </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://www.marketwatch.com/" target="_blank"> MarketWatch </a>
    </CCol>
    <CCol sm="auto">
      <a href="https://marketnews.com/" target="_blank"> MarketNews </a>
    </CCol>
    <CCol sm="auto">
      <a target="_blank" href="https://www.nerdwallet.com/mortgages/mortgage-rates"> Mortgage</a>
    </CCol>
  </CRow>
</CContainer>
        <CCollapse :visible="visible" v-if='visible'>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">TradingView</CInputGroupText>
            <CFormInput placeholder="Search TradingView" aria-label="TradingView" aria-describedby="button-addon2" v-model='secQuery' v-on:keyup.enter="searchTv"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchTv'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">EDGAR (SEC)</CInputGroupText>
            <CFormInput placeholder="Search SEC" aria-label="SEC" aria-describedby="button-addon2" v-model='secQuery' v-on:keyup.enter="searchSec"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchSec'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Seeking Alpha</CInputGroupText>
            <CFormInput placeholder="Search Youtube" aria-label="Youtube" aria-describedby="button-addon2" v-model='saQuery' v-on:keyup.enter="searchSa"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchSa'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Bloomberg</CInputGroupText>
            <CFormInput placeholder="Search Bloomberg" aria-label="Bloomberg" aria-describedby="button-addon2" v-model='saQuery' v-on:keyup.enter="searchBloom"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchBloom'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Open Insider</CInputGroupText>
            <CFormInput placeholder="Search Open Insider" aria-label="Open Insider" aria-describedby="button-addon2" v-model='oiQuery' v-on:keyup.enter="searchOi"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchOi'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Whale Wisdom</CInputGroupText>
            <CFormInput placeholder="Search Whale Wisdom" aria-label="Whale Wisdom" aria-describedby="button-addon2" v-model='whaleQuery' v-on:keyup.enter="searchWhale"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchWhale'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Twitter</CInputGroupText>
            <CFormInput placeholder="Search Twitter" aria-label="Twitter" aria-describedby="button-addon2" v-model='twitterQuery' v-on:keyup.enter="seachTwitter"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='seachTwitter'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Yahoo! Finance Quote</CInputGroupText>
            <CFormInput placeholder="Search Yahoo! Finance" aria-label="Yahoo! Finance" aria-describedby="button-addon2" v-model='yfQuery' v-on:keyup.enter="searchYf"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchYf'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Youtube</CInputGroupText>
            <CFormInput placeholder="Search Youtube" aria-label="Youtube" aria-describedby="button-addon2" v-model='youtubeQuery' v-on:keyup.enter="searchYoutube"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchYoutube'>Search</CButton>
        </CInputGroup>
        <CInputGroup class="mb-3">
            <CInputGroupText id="basic-addon3">Max Pain</CInputGroupText>
            <CFormInput placeholder="Search Max Pain" aria-label="Max Pain" aria-describedby="button-addon2" v-model='maxPainQuery' v-on:keyup.enter="searchMp"/>
            <CButton type="button" color="success" variant="outline" id="button-addon2" @click='searchMp'>Search</CButton>
        </CInputGroup>
        </CCollapse>
    </CCardBody>
    
    </CCard>
</CCol>

</CRow>
</template>

<script>
export default {
  name: 'SearchCenter',
  data() {
      return {
          visible: false,
          query: '',
          twitterQuery: '',
          yfQuery: '',
          youtubeQuery: '',
          saQuery: '',
          oiQuery: '',
          secQuery: '',
          whaleQuery: '',
          bloomQuery: '',
          maxPainQuery: '',
          tvQuery: '',
      }
  },
  methods: {
      seachTwitter: function() {
          window.open(`https://twitter.com/search?q=${this.twitterQuery.trim()}`, "_blank");
      },
      searchYf: function() {
          window.open(`https://finance.yahoo.com/quote/${this.yfQuery.trim()}`, "_blank");
      },
      searchYoutube: function() {
          window.open(`https://www.youtube.com/results?search_query=${this.youtubeQuery.trim()}`, "_blank");
      },
      searchSa: function() {
          window.open(`https://static.seekingalpha.com/javascripts/seeking_google.html?query=${this.saQuery.trim()}`, "_blank");
      },
      searchOi: function() {
          window.open(`http://openinsider.com/search?q=${this.oiQuery.trim()}`, "_blank");
      },
      searchSec: function() {
          window.open(`https://www.sec.gov/cgi-bin/browse-edgar?CIK=${this.secQuery.trim()}&Find=Search&owner=exclude&action=getcompany`, "_blank");
      },
      searchWhale: function() {
          window.open(`https://whalewisdom.com/stock/${this.whaleQuery.trim()}`, "_blank");
      },
      searchBloom: function() {
          window.open(`https://www.bloomberg.com/search?query=${this.bloomQuery.trim()}`, "_blank");
      },
      searchMp: function() {
          window.open(`https://swaggystocks.com/dashboard/options-max-pain/${this.maxPainQuery.trim()}`, "_blank");
      },
      searchTv: function() {
          window.open(`https://www.tradingview.com/chart/?share_your_love=ace95archer&symbol=${this.tvQuery.trim()}`, "_blank");
      },



      seachTwitter1: function() {
          window.open(`https://twitter.com/search?q=$${this.query.trim()}`, "_blank");
      },
      searchYf1: function() {
          window.open(`https://finance.yahoo.com/quote/${this.query.trim()}`, "_blank");
      },
      searchYoutube1: function() {
          window.open(`https://www.youtube.com/results?search_query=${this.query.trim()}`, "_blank");
      },
      searchSa1: function() {
          window.open(`https://static.seekingalpha.com/javascripts/seeking_google.html?query=${this.query.trim()}`, "_blank");
      },
      searchOi1: function() {
          window.open(`http://openinsider.com/search?q=${this.query.trim()}`, "_blank");
      },
      searchSec1: function() {
          window.open(`https://www.sec.gov/cgi-bin/browse-edgar?CIK=${this.query.trim()}&Find=Search&owner=exclude&action=getcompany`, "_blank");
      },
      searchWhale1: function() {
          window.open(`https://whalewisdom.com/stock/${this.query.trim()}`, "_blank");
      },
      searchBloom1: function() {
          window.open(`https://www.bloomberg.com/search?query=${this.query.trim()}`, "_blank");
      },
      searchMp1: function() {
          window.open(`https://swaggystocks.com/dashboard/options-max-pain/${this.query.trim()}`, "_blank");
      },
      searchTv1: function() {
          window.open(`https://www.tradingview.com/chart/?share_your_love=ace95archer&symbol=${this.query.trim()}`, "_blank");
      },
      searchCnbc1: function() {
          window.open(`https://www.cnbc.com/quotes/${this.query.trim()}`, "_blank");
      },
      searchHouse1: function() {
          window.open(`https://housestockwatcher.com/summary_by_ticker/${this.query.trim()}`, "_blank");
      },
      searchWsj1: function() {
          window.open(`https://www.wsj.com/search?&query=${this.query.trim()}`, "_blank");
      },
      searchWsb1: function() {
          window.open(`https://www.reddit.com/r/wallstreetbets/search/?q=${this.query.trim()}`, "_blank");
      },
      searchIbd1: function() {
          window.open(`https://iborrowdesk.com/report/${this.query.trim()}`, "_blank");
      },
      searchSt1: function() {
          window.open(`https://stocktwits.com/symbol/${this.query.trim()}`, "_blank");
      },
      searchUw1: function() {
          window.open(`https://unusualwhales.com/company/${this.query.trim()}/alerts`, "_blank");
      },
      searchFv1: function() {
          window.open(`https://finviz.com/quote.ashx?t=${this.query.trim()}`, "_blank");
      },
  }
}
</script>
