<template>
  <CRow>
  <CCol :md="12">
    <CCard class="mb-4">
    <CCardHeader> Recommendations <CButton color="success" variant="outline" class="float-end" size="sm" @click="visible = !visible"><CIcon icon="cilMenu" /></CButton> </CCardHeader>
      <CCardBody>
      <CCollapse :visible="visible">
      <CRow>
      <CCol>
        <CListGroup>
          <CListGroupItem component="a" target="_blank"
            href="https://www.amazon.com/gp/product/B01JNIAW3Y/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01JNIAW3Y&linkCode=as2&tag=vagrantbloom-20&linkId=b069b26ae37db45d89bfde6d96a1aa71">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Billions</h5>
              <small class="text-muted">TV Show</small>
            </div>
            <p class="mb-1">
              S-Tier Market related Drama. Team Axe!
            </p>
          </CListGroupItem>
          <CListGroupItem
            component="a"
            href="https://go.fiverr.com/visit/?bta=343132&brand=fiverrcpa"
            target="_blank"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Fiverr</h5>
              <small class="text-muted">Service</small>
            </div>
            <p class="mb-1">
              Are you sick and tired of doing it yourself like I am right now?
            </p>
          </CListGroupItem>
          <CListGroupItem component="a" target="_blank"
            href="https://www.amazon.com/gp/product/B019969US8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B019969US8&linkCode=as2&tag=vagrantbloom-20&linkId=327d04d2cc40b34ac0f9a60b24d4dacd">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">The Big Short</h5>
              <small class="text-muted">Movie</small>
            </div>
            <p class="mb-1">
              You never heard of Michael Burry?
            </p>
          </CListGroupItem>
          <CListGroupItem component="a" href="https://www.tradingview.com/gopro/?share_your_love=ace95archer" target="_blank">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">TradingView</h5>
              <small class="text-muted">Charting Platform</small>
            </div>
            <p class="mb-1">
              Do you even TA?
            </p>
          </CListGroupItem>
        </CListGroup>
      </CCol>
      <CCol>
        <CListGroup>
          <CListGroupItem
            component="a"
            href="https://www.amazon.com/gp/product/0060555661/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0060555661&linkCode=as2&tag=vagrantbloom-20&linkId=67a8de2c2390970a71ef99dcd9962aae"
            target="_blank"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">The Intelligent Investor</h5>
              <small class="text-muted">Book</small>
            </div>
            <p class="mb-1">
              Mr. Buffet says: Read it, <s>faggot</s> Friend!
            </p>
          </CListGroupItem>
          <CListGroupItem component="a" href="https://share.public.com/dylor" target="_blank">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Public.com</h5>
              <small class="text-muted">Stock/Crypto Platform</small>
            </div>
            <p class="mb-1">
              LOL you still on Robinhood?
            </p>
          </CListGroupItem>
          <CListGroupItem component="a" href="https://join.robinhood.com/yid9/" target="_blank">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Robinhood</h5>
              <small class="text-muted">Stock/Options Platform</small>
            </div>
            <p class="mb-1">
              LMAO you don't even have Robinhood?
            </p>
          </CListGroupItem>
          <CListGroupItem component="a" href="https://blockfi.com/?ref=39b9592a" target="_blank">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">BlockFi</h5>
              <small class="text-muted">Crypto Platform</small>
            </div>
            <p class="mb-1">
              You never heard of interest on Crypto?
            </p>
          </CListGroupItem>
        </CListGroup>
      </CCol>
      </CRow>
      </CCollapse>
      </CCardBody>
    </CCard>
  </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'Ads',
  data() {
      return {
        visible: true,
      }
  },
}
</script>
