<template>

<CRow>
<CCol :md="12">
<CCard class="mb-4">
<CCardHeader> <CAvatar :src="info.logo_url" shape="rounded" /> News <CButton color="success" variant="outline" class="float-end" size="sm" @click="visible = !visible"><CIcon icon="cilMenu" /></CButton> </CCardHeader>
<CCollapse :visible="visible">
<CCardBody v-if="loaded">
    <CTable align="middle" class="mb-0 border" hover responsive>
    <CTableBody>
        <CTableRow v-for="(item, i) in news" :key="i">
            <CTableDataCell class="text-center">
                {{item.publisher}}
            </CTableDataCell>
            <CTableDataCell class="text-center">
                {{item.type}}
            </CTableDataCell>
            <CTableDataCell>
                <a :href='item.link' target="_blank"> {{item.title}}</a>
            </CTableDataCell>
            
        </CTableRow>
    </CTableBody>
    </CTable>
</CCardBody>
</CCollapse>
</CCard>
</CCol>
</CRow>

</template>

<script>
export default {
  name: 'News',
  props:{
      info: {},
      news: {},
      loaded: {
          type: Boolean, 
          default: false
      }
  },
  data() {
      return {
        visible: true,
      }
  },

}
</script>
