<template>

<CRow>
<CCol :md="12">
<CCard class="mb-4">
<CCardHeader> <CAvatar :src="info.logo_url" shape="rounded" /> institutional Holders (Source: Yahoo Finance)<CButton color="success" variant="outline" class="float-end" size="sm" @click="visible = !visible"><CIcon icon="cilMenu" /></CButton> </CCardHeader>
<CCollapse :visible="visible">
<CCardBody v-if="loaded">

    <CTable align="middle" class="mb-0 border" hover responsive>
    <CTableHead color="light">
        <CTableRow>
            <CTableHeaderCell class="text-center">Holder</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Shares</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Date Reported</CTableHeaderCell>
            <CTableHeaderCell class="text-center">% Out</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Value</CTableHeaderCell>
        </CTableRow>
    </CTableHead>
    <CTableBody>
        <CTableRow v-for="(item, i) in inst" :key="i">
            <CTableDataCell class="text-center">
                <div>{{ item.Holder }}</div>
            </CTableDataCell>
            <CTableDataCell class="text-center">
                <div>{{ item.Shares }}</div>
            </CTableDataCell>
            <CTableDataCell class="text-center">
                <div>{{ item['Date Reported'].substring(0, 10) }}</div>
            </CTableDataCell>
            <CTableDataCell class="text-center">
                <div>{{ Math.round(item['% Out'] * 100) }}%</div>
            </CTableDataCell>
            <CTableDataCell class="text-center">
                <div>{{ item.Value }}</div>
            </CTableDataCell>
        </CTableRow>
    </CTableBody>
    </CTable>
</CCardBody>
</CCollapse>
</CCard>
</CCol>
</CRow>

</template>

<script>
export default {
  name: 'InstitutionalSection',
  props:{
      info: {},
      inst: {},
      loaded: {
          type: Boolean, 
          default: false
      }
  },
  data() {
      return {
        visible: true,
      }
  },

}
</script>
