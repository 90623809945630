<template>
  <CCol :md="12">
    <CCard class="mb-4">
      <CCardHeader>
        <CAvatar :src="sitems.logo_url" shape="rounded" />
        {{ sitems.shortName }} (Source: Yahoo Finance)
        
        <CButton
          color="success"
          variant="outline"
          class="float-end"
          size="sm"
          @click="fivisible = !fivisible"
          ><CIcon icon="cilMenu"
        /></CButton>
        <CButton
          color="success"
          variant="outline"
          class="float-end"
          size="sm"
          @click="visible = !visible"
          >Description</CButton>
      </CCardHeader>
      <CCollapse :visible="visible">
        <CRow>
          <CCol :md="12">
            <CWidgetStatsF
              color="secondary"
              :padding="true"
              :title="`${sitems.longBusinessSummary}`"
              :value="`${sitems.shortName} (Industry: ${sitems.industry})`"
            >
              <CAvatar :src="sitems.logo_url" class="rounded-0" />
            </CWidgetStatsF>
          </CCol>
        </CRow>
      </CCollapse>
      <CRow>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="warning"
            title="Trailing EPS"
            :value="sitems.trailingEps"
          >
            <template #icon>
              <CIcon icon="cilCalculator" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="primary"
            title="Institute Owned"
            :value="`${sitems.heldPercentInstitutions * 100}%`"
          >
            <template #icon>
              <CIcon icon="cilChartPie" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="primary"
            title="Insider Owned"
            :value="`${sitems.heldPercentInsiders * 100}%`"
          >
            <template #icon>
              <CIcon icon="cilChartPie" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="info"
            title="Market Cap"
            :value="new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sitems.marketCap)"
          >
            <template #icon>
              <CIcon icon="cilSpeedometer" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
      </CRow>
      <CRow>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="success"
            title="Free Cash Flow"
            :value="new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sitems.freeCashflow)"
          >
            <template #icon>
              <CIcon icon="cilDollar" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="success"
            title="Total Cash"
            :value="new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sitems.totalCash)"
          >
            <template #icon>
              <CIcon icon="cilDollar" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="success"
            title="Total Debt"
            :value="new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sitems.totalDebt)"
          >
            <template #icon>
              <CIcon icon="cilDollar" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="success"
            title="Total Cash Per Share"
            :value="new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sitems.totalCashPerShare)"
          >
            <template #icon>
              <CIcon icon="cilDollar" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        
      </CRow>
      <CRow>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="danger"
            title="Shares Outstanding"
            :value="new Intl.NumberFormat().format(sitems.sharesOutstanding)"
          >
            <template #icon>
              <CIcon icon="cilArrowBottom" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="danger"
            title="Shares Short"
            :value="Intl.NumberFormat().format(sitems.sharesShort)"
          >
            <template #icon>
              <CIcon icon="cilArrowBottom" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="danger"
            title="Short Percentage of Float"
            :value="`${sitems.shortPercentOfFloat * 100}%`"
          >
            <template #icon>
              <CIcon icon="cilArrowBottom" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
        <CCol :sm="6" :lg="3">
          <CWidgetStatsF
            color="danger"
            title="Short Prior Month"
            :value="Intl.NumberFormat().format(sitems.sharesShortPriorMonth)"
          >
            <template #icon>
              <CIcon icon="cilArrowBottom" size="xl" />
            </template>
          </CWidgetStatsF>
        </CCol>
      </CRow>
      
      <CRow>
        <CCol>
        <CCollapse :visible="fivisible">
          <CTable striped align="middle" class="mb-0 border" hover responsive>
            <CTableBody>
              <CTableRow>
                <CTableHeaderCell scope="row">Avg Daily Volume</CTableHeaderCell>
                <CTableDataCell>{{Intl.NumberFormat().format(sitems.averageDailyVolume10Day)}}</CTableDataCell>
                <CTableHeaderCell scope="row">Book Value</CTableHeaderCell>
                <CTableDataCell>{{sitems.bookValue}}</CTableDataCell>
                <CTableHeaderCell scope="row">Short Interest</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.dateShortInterest)}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">EBITDA</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.ebitda)}}</CTableDataCell>
                <CTableHeaderCell scope="row">EBITDA Margins</CTableHeaderCell>
                <CTableDataCell>{{sitems.ebitdaMargins}}</CTableDataCell>
                <CTableHeaderCell scope="row">Enterprise To EBITDA</CTableHeaderCell>
                <CTableDataCell>{{sitems.enterpriseToEbitda}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Forward EPS</CTableHeaderCell>
                <CTableDataCell>{{sitems.forwardEps}}</CTableDataCell>
                <CTableHeaderCell scope="row">Forward PE</CTableHeaderCell>
                <CTableDataCell>{{sitems.forwardPE}}</CTableDataCell>
                <CTableHeaderCell scope="row">Full Time Employees</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.fullTimeEmployees)}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">PEG Ratio</CTableHeaderCell>
                <CTableDataCell>{{sitems.pegRatio}}</CTableDataCell>
                <CTableHeaderCell scope="row">Price To Book</CTableHeaderCell>
                <CTableDataCell>{{sitems.priceToBook}}</CTableDataCell>
                <CTableHeaderCell scope="row">Price To Sales</CTableHeaderCell>
                <CTableDataCell>{{sitems.priceToSalesTrailing12Months}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Profit Margins</CTableHeaderCell>
                <CTableDataCell>{{sitems.profitMargins}}</CTableDataCell>
                <CTableHeaderCell scope="row">Quick Ratio</CTableHeaderCell>
                <CTableDataCell>{{sitems.quickRatio}}</CTableDataCell>
                <CTableHeaderCell scope="row">Revenue Per Share</CTableHeaderCell>
                <CTableDataCell>{{sitems.revenuePerShare}}</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          </CCollapse>
        </CCol>
        <CCol>
        <CCollapse :visible="fivisible">
          <CTable striped align="middle" class="mb-0 border" hover responsive>
            <CTableBody>
              <CTableRow>
                <CTableHeaderCell scope="row">Debt to Equity</CTableHeaderCell>
                <CTableDataCell>{{sitems.debtToEquity}}</CTableDataCell>
                <CTableHeaderCell scope="row">Dividend Rate</CTableHeaderCell>
                <CTableDataCell>{{sitems.dividendRate}}</CTableDataCell>
                <CTableHeaderCell scope="row">Dividend Yield</CTableHeaderCell>
                <CTableDataCell>{{sitems.dividendYield}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">ENT. To Revenue</CTableHeaderCell>
                <CTableDataCell>{{sitems.enterpriseToRevenue}}</CTableDataCell>
                <CTableHeaderCell scope="row">Enterprise Value</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.enterpriseValue)}}</CTableDataCell>
                <CTableHeaderCell scope="row">Float Shares</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.floatShares)}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Gross Margins</CTableHeaderCell>
                <CTableDataCell>{{sitems.grossMargins}}</CTableDataCell>
                <CTableHeaderCell scope="row">Gross Profits</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.grossProfits)}}</CTableDataCell>
                <CTableHeaderCell scope="row">Short Ratio</CTableHeaderCell>
                <CTableDataCell>{{sitems.shortRatio}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Operating Cashflow</CTableHeaderCell>
                <CTableDataCell>{{new Intl.NumberFormat().format(sitems.operatingCashflow)}}</CTableDataCell>
                <CTableHeaderCell scope="row">Operating Margins</CTableHeaderCell>
                <CTableDataCell>{{sitems.operatingMargins}}</CTableDataCell>
                <CTableHeaderCell scope="row">Payout Ratio</CTableHeaderCell>
                <CTableDataCell>{{sitems.payoutRatio}}</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Return On Assets</CTableHeaderCell>
                <CTableDataCell>{{sitems.returnOnAssets}}</CTableDataCell>
                <CTableHeaderCell scope="row">Return On Equity</CTableHeaderCell>
                <CTableDataCell>{{sitems.returnOnEquity}}</CTableDataCell>
                <CTableHeaderCell scope="row">Revenue Growth</CTableHeaderCell>
                <CTableDataCell>{{sitems.revenueGrowth}}</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          </CCollapse>
        </CCol>
      </CRow>
      
    </CCard>
  </CCol>
</template>

<script>
// import axios from 'axios'
export default {
  name: 'StockFinancials',
  components: {},
  props: {
    symbol: String,
    sitems: {},
  },
  data() {
    return {
      data_loaded: false,
      visible: false,
      fivisible: true,
      // sitems: [],
    }
  },
  methods: {
    // async getData() {
    //       axios
    //       .get('https://vbapi-hepeem5jva-uc.a.run.app/snap', {
    //           headers: {
    //               'ticker':this.symbol,
    //           }
    //       }).then(response => {
    //           this.sitems = response.data;
    //           this.data_loaded = true;
    //       })
    //   },
    //   formatPrice(value) {
    //       let val = (value/1).toFixed(2).replace('.', ',')
    //       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    //   }
  },
  created() {
    // this.getData()
  },
}
</script>
