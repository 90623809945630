<template>

  <div>


  <Stocktwits symbol="SPY" />

  <Earnings />


  <Lockup />

  <Finviz 
    symbol="SPY" 
    />

 <Ads />


  

  </div>
</template>

<script>
import Stocktwits from './sections/StocktwitsSection.vue'
import Lockup from './sections/LockupSection.vue'
import Finviz from './sections/FinvizSection.vue'
import Ads from './sections/AdsSection.vue'
// import GDP from './sections/GdpSection.vue'
import Earnings from './sections/EarningsSection.vue'
// import CongressDailySection from './sections/CongressDailySection.vue'

export default {
  name: 'Marketboard',
  components: {
      Stocktwits,
      Lockup,
      Finviz,
      Ads,
      // GDP,
      Earnings,
        // CongressDailySection
  },
  data () {
    return {
      
    }
  },
  setup() {
    

    return {

    }
  },
}
</script>
