<template>

  <div>
  <CRow v-if='!data_loaded'>
  <CCol class="text-center">
  <CSpinner color="success" variant="grow" />
  </CCol>
  </CRow>

  <StockFinancials
    :sitems='info' 
    :key = 'info'
     />

  <CRow v-if='!data_loaded'>
  <CCol class="text-center">
  <CSpinner color="success" variant="grow" />
  </CCol>
  </CRow>

  <News :key = 'news'
    :news = 'news'
    :loaded = 'data_loaded'
    :info = 'info'
    />
  
  <Ads />

  <Finviz 
  :symbol='ticker' 
    :key = 'ticker'
    />
 

    <Stocktwits 
     :symbol='ticker' 
    :key = 'ticker'
    />
    
    <OpenInterestSec 
    :symbol='ticker' 
    :key = 'ticker'
    />

  <CRow v-if='!data_loaded'>
  <CCol class="text-center">
  <CSpinner color="success" variant="grow" />
  </CCol>
  </CRow>

        <InstitutionalSection   
    :key = 'institutional_holders'
    :inst = 'institutional_holders'
    :loaded = 'data_loaded'
    :info = 'info'
    />
  </div>
</template>

<script>
import OpenInterestSec from './sections/OpenInsiderSection.vue'
import Stocktwits from './sections/StocktwitsSection.vue'
import Finviz from './sections/FinvizSection.vue'
import StockFinancials from './sections/StockFinancialSection.vue'
import Ads from './sections/AdsSection.vue'
import InstitutionalSection from './sections/InstitutionalSection.vue'
import News from './sections/NewsSection.vue' 
import axios from 'axios'

export default {
  name: 'Dashboard',
  props: ['ticker'],
  components: {
    OpenInterestSec,
    Stocktwits,
    Finviz,
    StockFinancials,
    Ads,
    InstitutionalSection,
    News,
  },
  data() {
      return {
        data_loaded: false,
        visible: false,
        everything:{},
        info:{},
        financials:{},
        calendar:{},
        recommendations: {},
        institutional_holders: {},
        news: {},
      }
  },
   methods:{
      async getData() {
            axios
            .get('https://vbapi-hepeem5jva-uc.a.run.app/stonk', {
                headers: {
                    'ticker':this.ticker,
                }
            }).then(response => {
                this.everything = response.data;
                this.data_loaded = true;
                this.info = response.data.info;
                this.financials = response.data.financials;
                this.calendar = JSON.parse(response.data.calendar);
                this.recommendations = JSON.parse(response.data.recommendations);
                this.institutional_holders = JSON.parse(response.data.institutional_holder);
                this.news = response.data.news;
            })
        }, 
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
  },
  created(){
      this.getData()
  }
}
</script>
